import { PlainObject } from 'shared/util/types.util'
import { TrackerWithAutoTracking } from 'analytics/OmniTracker/Tracker'
import { PostHog } from 'posthog-js'

export default class PosthogTracker implements TrackerWithAutoTracking {
  private posthog: PostHog

  constructor(posthog: PostHog) {
    this.posthog = posthog
  }

  trackEvent(name: string, payload: PlainObject): void {
    this.posthog.capture(name, payload)
  }

  resetSession(): void {
    this.posthog.reset()
  }

  setAutoTrackingProperties(properties: PlainObject): void {
    this.posthog.register(properties)
  }
}
