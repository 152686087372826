import { PlainObject } from 'shared/util/types.util'
import Tracker from 'analytics/OmniTracker/Tracker'
import Ahoy from 'analytics/ahoy.type'

export default class AhoyTracker implements Tracker {
  private ahoy: Ahoy

  constructor(ahoy: Ahoy) {
    this.ahoy = ahoy
  }

  trackEvent(name: string, payload: PlainObject): void {
    const eventProperties = {
      page: window.location.pathname,
      url: window.location.href,
      ...payload,
    }

    this.ahoy.track(name, eventProperties)
  }

  resetSession(): void {
    this.ahoy.reset()
  }
}
